function addToCartProduct(e) {
    e.preventDefault();

    const addToCartUrl = $(this).attr('href');
    const form = {
        pid: $(this).data('pid'),
        quantity: 1
    };

    if (addToCartUrl) {
        $.spinner().start();
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                if(data.layer) {
                    dataLayer.push(data.layer);
                }

                $('.minicart').trigger('count:update', data);
                $('body').trigger('product:afterAddToCart', data);
                $('body').trigger('minicart:afterAddToCart');
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

$(() => {
    $('body').on('click', '.add-to-cart-product', addToCartProduct)
});